import {createContext, useContext} from 'react';
import {UserPublicDataType} from "../types/authTypes";

export const UserContext = createContext<UserPublicDataType | undefined>(undefined);

export function useUserContext(){
    const userInfo = useContext(UserContext);

    if (userInfo === undefined){
        throw new Error("useUserContext must be used with a UserContext");
    }

    return userInfo;
}

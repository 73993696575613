import React from 'react';
import Header from '../modules/Header';
import {Box} from '@mui/material';
import BuildingsTable from '../modules/BuildingsTable';

function MainPage() {
    return (
        <>
            <Header/>
                {
                    <Box sx={{margin: 'auto', width: '80%'}}>
                        <BuildingsTable/>
                    </Box>
                }
        </>
    )
}


export default MainPage;

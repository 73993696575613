import React from 'react';
import Header from '../modules/Header';
import {Box} from '@mui/material';
import BuildingDevicesCoreTable from '../modules/BuildingDevicesCoreTable';
import BuildingDevicesDistroTable from '../modules/BuildingDevicesDistroTable'
import BuildingDevicesAccessSwitchTable from '../modules/BuildingDevicesAccessSwitchTable'
import BuildingDevicesHostTable from '../modules/BuildingDevicesHostTable';
import Typography from '@mui/material/Typography';
import {useParams} from 'react-router-dom';
import {
  BuildingDevicesHostDataType,
  BuildingDevicesStructureDataType,
  BuildingNameDataType
} from '../../types/buildingTypes';
import useRegionQueryParam from '../../hooks/useRegionQueryParam';
import {ApiInstance} from '../../api/api';
import {DevicesHostsDataContext} from '../../context/devicesHostsDataContext';

function DevicesPage() {
    const { building } = useParams<BuildingNameDataType>();
    const { selectedRegion } = useRegionQueryParam();
    const [isLoading, setIsLoading] = React.useState(false);
    const [allHosts, setAllHosts] = React.useState<BuildingDevicesHostDataType[]>([]);
    const [hosts, setHosts] = React.useState<BuildingDevicesHostDataType[]>([]);
    const [cores, setCores] = React.useState<BuildingDevicesStructureDataType[]>([]);
    const [distros, setDistros] = React.useState<BuildingDevicesStructureDataType[]>([]);
    const [accessSwitches, setAccessSwitches] = React.useState<BuildingDevicesStructureDataType[]>([]);
    const [loadingState, setLoadingState] = React.useState<Boolean>(false);

    const getBuildingDevices = async () => {
      setIsLoading(true);

      const buildingDevices = await ApiInstance.getBuildingDevices(building, selectedRegion);

      setAllHosts(buildingDevices.host);
      setHosts(buildingDevices.host);
      setCores(buildingDevices.core);
      setDistros(buildingDevices.distro);
      setAccessSwitches(buildingDevices.access_switch);

      setIsLoading(false);
    }

    React.useEffect(() => {
      getBuildingDevices()
    }, []);

    return (
        <>
            <Header/>
                {
                    <Box sx={{margin: 'auto', width: '80%'}}>
                        <Typography
                          sx={{ flex: '1 1 100%' }}
                          component="h1"
                          variant="h4"
                          align="center"
                          margin={3}
                        >
                          Building manager - Region: {selectedRegion}, building: {building}
                        </Typography>
                        <BuildingDevicesCoreTable
                            cores={cores}
                            isLoading={isLoading}
                        />
                        <BuildingDevicesDistroTable
                            distros={distros}
                            isLoading={isLoading}
                        />
                        <BuildingDevicesAccessSwitchTable
                            accessSwitches={accessSwitches}
                            isLoading={isLoading}
                        />
                        <DevicesHostsDataContext.Provider value={{allHosts, hosts, loadingState, setHosts, setLoadingState}}>
                            <BuildingDevicesHostTable
                                hosts={hosts}
                                isLoading={isLoading}
                            />
                        </DevicesHostsDataContext.Provider>
                    </Box>
                }
        </>
    )
}


export default DevicesPage;

import React from 'react';
import Header from '../modules/Header';
import {Box} from '@mui/material';
import StackTable from '../modules/distro/StackTable';
import SwitchPortsTable from "../modules/distro/SwitchPortsTable";
import {useParams} from 'react-router-dom';
import {DistroNameDataType} from '../../types/buildingTypes';
import useRegionQueryParam from '../../hooks/useRegionQueryParam';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import useVendorQueryParam from '../../hooks/useVendorQueryParam';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

function DistroDHCPDevicesPage() {
    const { distro } = useParams<DistroNameDataType>();
    const { selectedRegion } = useRegionQueryParam();
    const { selectedVendor } = useVendorQueryParam();

    return (
        <>
            <Header/>
                {
                    <Box sx={{margin: 'auto', width: '80%'}}>
                        <Typography
                          sx={{ flex: '1 1 100%' }}
                          component="h1"
                          variant="h4"
                          align="center"
                          margin={3}
                        >
                            <Tooltip
                                title="ARP/DHCP table is not available for the selected distro!"
                            >
                                <InfoOutlinedIcon fontSize="medium" color="warning" />
                            </Tooltip>
                            Distro manager - IP: {distro}, Region: {selectedRegion}
                        </Typography>
                        <StackTable
                            device={"distro"}
                        />
                        {(() => {
                            if (selectedVendor === 'cisco') {
                                return (
                                    <SwitchPortsTable
                                        device={"distro"}
                                    />
                                )
                            } else {
                                return (
                                    <Card variant="outlined">
                                        <CardContent>
                                            <Typography
                                                sx={{ flex: '1 1 100%' }}
                                                component="h4"
                                                variant="h4"
                                                align="center"
                                                margin={3}
                                            >
                                                The switch ports table is not yet available for the {selectedVendor} vendor.
                                                This functionality is currently in development.
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                )
                            }
                        })()}
                    </Box>
                }
        </>
    )
}


export default DistroDHCPDevicesPage;

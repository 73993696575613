import {createContext, useContext} from 'react';
import {DeviceARPDataType} from "../types/buildingTypes";

interface CoreDevicesDataContextInterface{
    allARPEntries: DeviceARPDataType[] | undefined;
    arpEntries: DeviceARPDataType[] | undefined;
    loadingState: Boolean | undefined,
    setARPEntries: any,
    setLoadingState: any;
}

export const ArpEntriesDataContext = createContext<CoreDevicesDataContextInterface>({
  allARPEntries: undefined,
  arpEntries: undefined,
  loadingState: false,
  setARPEntries: {},
  setLoadingState: undefined,
});

export function useARPEntriesDataContext(){
    const { allARPEntries, arpEntries, setARPEntries, loadingState, setLoadingState} = useContext(ArpEntriesDataContext);

    if (arpEntries === undefined || allARPEntries === undefined){
        throw new Error("useARPEntriesDataContext must be used with a ArpEntriesDataContext");
    }

    return {allARPEntries, arpEntries, setARPEntries, loadingState, setLoadingState};
}

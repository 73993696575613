import { useLocation } from 'react-router-dom';

const useVendorQueryParam = (): { selectedVendor: string | null } => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const vendor = queryParams.get('vendor');
  return { selectedVendor: vendor ? vendor.toLowerCase() : null };
};


export default useVendorQueryParam;

import { jwtDecode } from "jwt-decode";

class Utils {
    checkIsValidToken = (token: string, cb: () => void) => {
        const decode: {
            exp: number;
        } = jwtDecode(token);
        if (decode.exp < +new Date() / 1000) {
            cb();
        }
    };
}

export const utils_instance = new Utils();

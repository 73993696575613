import React from 'react';
import Header from '../modules/Header';
import {Box} from '@mui/material';
import Typography from '@mui/material/Typography';

function NotFoundPage() {
    return (
        <>
            <Header/>
                {
                    <Box sx={{
                        height: '60vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center'
                    }}>
                        <Typography
                            sx={{flex: '1 1 100%'}}
                            variant="h1"
                            component="h2"
                        >
                            404 - Page Not Found
                        </Typography>
                    </Box>
                }
        </>
    )
}


export default NotFoundPage;

import React from 'react';
import Header from '../modules/Header';
import {Box} from '@mui/material';
import CoreDevicesTable from '../modules/CoreDevicesTable';
import Typography from '@mui/material/Typography';
import {useParams} from 'react-router-dom';
import {CoreNameDataType} from '../../types/buildingTypes';
import useRegionQueryParam from '../../hooks/useRegionQueryParam';


function CoreDevicesPage() {
    const { core } = useParams<CoreNameDataType>();
    const { selectedRegion } = useRegionQueryParam();

    return (
        <>
            <Header/>
                {
                    <Box sx={{margin: 'auto', width: '80%'}}>
                        <Typography
                          sx={{ flex: '1 1 100%' }}
                          component="h1"
                          variant="h4"
                          align="center"
                          margin={3}
                        >
                          Core manager - IP: {core}, Region: {selectedRegion}
                        </Typography>
                        <CoreDevicesTable/>
                    </Box>
                }
        </>
    )
}


export default CoreDevicesPage;

import {createContext, useContext} from 'react';
import {DeviceSwitchPortsDataType} from "../types/buildingTypes";

interface CoreDevicesDataContextInterface{
    allSwitchPorts: DeviceSwitchPortsDataType[] | undefined;
    switchPorts: DeviceSwitchPortsDataType[] | undefined;
    loadingState: Boolean | undefined,
    setSwitchPorts: any,
    setLoadingState: any;
}

export const SwitchPortsDataContext = createContext<CoreDevicesDataContextInterface>({
  allSwitchPorts: undefined,
  switchPorts: undefined,
  loadingState: false,
  setSwitchPorts: {},
  setLoadingState: undefined,
});

export function useSwitchPortsDataContext(){
    const { allSwitchPorts, switchPorts, setSwitchPorts, loadingState, setLoadingState} = useContext(SwitchPortsDataContext);

    if (switchPorts === undefined || allSwitchPorts === undefined){
        throw new Error("useSwitchPortsDataContext must be used with a SwitchPortsDataContext");
    }

    return {allSwitchPorts, switchPorts, setSwitchPorts, loadingState, setLoadingState};
}

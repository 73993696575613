import { useLocation } from 'react-router-dom';

const useSiteQueryParam = (): { selectedSite: string | null } => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const site = queryParams.get('site');
  return { selectedSite: site ? site.toLowerCase() : null };
};


export default useSiteQueryParam;

import React, {useEffect, useState} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import {useAuth0} from "@auth0/auth0-react";
import MainPage from "./components/pages/MainPage";
import DevicesPage from "./components/pages/DevicesPage";
import CoreDevicesPage from "./components/pages/CoreDevicesPage";
import DistroDHCPDevicesPage from "./components/pages/DistroDHCPDevicesPage";
import DistroDevicesPage from "./components/pages/DistroDevicesPage";
import AccessSwitchesPage from "./components/pages/AccessSwitchesPage";
import NotFoundPage from "./components/pages/NotFoundPage";
import {CircularProgress} from "@mui/material";
import {ApiInstance} from "./api/api";
import {utils_instance} from "./utils/Utils";
import {UserPublicDataType} from "./types/authTypes";
import { UserContext } from './context/userConext';

const _App: React.FC = () => {
    const {isAuthenticated, user, loginWithRedirect, isLoading, error, getAccessTokenSilently, logout} = useAuth0();

    const [userInfo, setUserInfo] = React.useState<UserPublicDataType | undefined>();
    const [userStatusProgress, setUserStatusProgress] = React.useState(true);

    let token = localStorage.getItem('token')

    const getUserInfo = async () => {
        const user = await ApiInstance.getUserInfo();
        setUserInfo(user);
        setUserStatusProgress(false);
    }

    const loadData = async () => {
        if (isAuthenticated) {
            const token = await getAccessTokenSilently()

            localStorage.setItem('token', token)
            ApiInstance.createAxiosInstance(token);

            getUserInfo()
        }

        if (!isAuthenticated && !isLoading) {
            loginWithRedirect()
        }

        if (token) {
          utils_instance.checkIsValidToken(token, loginWithRedirect);
        }
    }

    useEffect(() => {
        loadData()
    }, [isAuthenticated, isLoading, token])


    if ((!token && userStatusProgress) || !userInfo) {
        return (
            <CircularProgress/>
        );
    }

    return (
        <UserContext.Provider value={userInfo}>
            <Router>
                <Routes>
                    <Route path="/" element={<MainPage />} />
                    <Route path="/devices/:building" element={<DevicesPage />} />
                    <Route path="/devices/core/:core" element={<CoreDevicesPage />} />
                    <Route path="/devices/distro/:distro" element={<DistroDevicesPage />} />
                    <Route path="/devices/distro-dhcp/:distro" element={<DistroDHCPDevicesPage />} />
                    <Route path="/devices/switch/:access_switch" element={<AccessSwitchesPage />} />
                    <Route path="*" element={<NotFoundPage />} />
                </Routes>
            </Router>
        </UserContext.Provider>
    )
};


function App() {
    return (
        <_App/>
    );
}

export default App;

import axios from 'axios';
import config from "../config";
import {UserPublicDataType} from "../types/authTypes";
import {BuildingDataType} from "../types/buildingTypes";

class ApiInstanceClass {
    axiosInstance = axios.create({
        baseURL: config.apiUrl,
    });

    createAxiosInstance(token: string) {
      this.axiosInstance = axios.create({
        withCredentials: true,
        baseURL: config.apiUrl,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    }

    getAxiosInstance() {
        return this.axiosInstance;
    }

    getUserInfo() {
        return ApiInstance.getAxiosInstance()
          .get<UserPublicDataType>('user/info')
          .then((r) => r.data);
    }

    getBuildings() {
        return ApiInstance.getAxiosInstance()
          .get<BuildingDataType[]>('buildings/')
          .then((r) => r.data)
          .catch(function (error) {
              //TODO: Add logic for sending notifications
              return [];
          });
    }

    getBuildingDevices(building: string | undefined, region: string | null) {
        return ApiInstance.getAxiosInstance()
          .get('buildings/devices', {
          params: { building, region },
          })
          .then((r) => r.data)
          .catch(function (error) {
              //TODO: Add logic for sending notifications
              return [];
          });
    }

    getCoreDevices(ip_address: string | undefined, device_type: string | null, region: string | null) {
        return ApiInstance.getAxiosInstance()
          .get('buildings/devices/core', {
          params: { ip_address, device_type, region },
          })
          .then((r) => r.data)
          .catch(function (error) {
              //TODO: Add logic for sending notifications
              return [];
          });
    }

    getDistroARP(ip_address: string | undefined, device_type: string | null, region: string | null) {
        return ApiInstance.getAxiosInstance()
          .get('buildings/devices/distro/arp', {
          params: { ip_address, device_type, region },
          })
          .then((r) => r.data)
          .catch(function (error) {
              return [];
          });
    }

    getDistroStack(ip_address: string | undefined, device_type: string | null, region: string | null) {
        return ApiInstance.getAxiosInstance()
          .get('buildings/devices/distro/stack', {
          params: { ip_address, device_type, region },
          })
          .then((r) => r.data)
          .catch(function (error) {
              return [];
          });
    }

    getSwitchPorts(ip_address: string | undefined, device_type: string | null, region: string | null) {
        return ApiInstance.getAxiosInstance()
          .get('buildings/devices/switch/switch-ports', {
          params: { ip_address, device_type, region },
          })
          .then((r) => r.data)
          .catch(function (error) {
              return [];
          });
    }

    getSwitchStack(ip_address: string | undefined, device_type: string | null, region: string | null) {
        return ApiInstance.getAxiosInstance()
          .get('buildings/devices/switch/stack', {
          params: { ip_address, device_type, region },
          })
          .then((r) => r.data)
          .catch(function (error) {
              return [];
          });
    }
}

export const ApiInstance = new ApiInstanceClass();

import {createContext, useContext} from 'react';
import {BuildingDataType} from "../types/buildingTypes";

interface BuildingDataContextInterface{
    allBuildings: BuildingDataType[] | undefined;
    buildings: BuildingDataType[] | undefined;
    selected: BuildingDataType[] | undefined;
    loadingState: Boolean | undefined,
    setBuildings: any,
    setLoadingState: any;
}

export const BuildingDataContext = createContext<BuildingDataContextInterface>({
  allBuildings: undefined,
  buildings: undefined,
  selected: undefined,
  loadingState: false,
  setBuildings: {},
  setLoadingState: undefined,
});

export function useBuildingDataContext(){
    const { allBuildings, buildings, selected, setBuildings, loadingState, setLoadingState} = useContext(BuildingDataContext);

    if (buildings === undefined || allBuildings === undefined){
        throw new Error("useBuildingDataContext must be used with a buildingDataContext");
    }

    return {allBuildings, buildings, selected, setBuildings, loadingState, setLoadingState};
}

import {useDevicesHostsDataContext} from "../../context/devicesHostsDataContext";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import {BuildingDevicesHostDataType} from "../../types/buildingTypes";

export default function BuildingSearch() {
    const {allHosts, setHosts} = useDevicesHostsDataContext();

    const handleSearchInput = (e: any) => {
        e.preventDefault();
        const searchValue: string = e.target.value.toLowerCase();

        function filterByMac(objects: BuildingDevicesHostDataType[], searchString: string) {
            return objects.filter(obj => obj.mac.toLowerCase().includes(searchString));
        }

        if (searchValue === ''){
            setHosts(allHosts);
        }
        else{
            const filteredArray = filterByMac(allHosts, searchValue);
            setHosts(filteredArray);
        }
    };

    return (
        <TextField
            label="Search by MAC"
            size="small"
            sx={{width: '40ch', margin: '5pt', height: '30pt'}}
            onChange={handleSearchInput}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton>
                            <SearchIcon/>
                        </IconButton>
                    </InputAdornment>
                )
            }}
        />
    )
}

import React from 'react';
import Box from "@mui/material/Box";
import {AppBar, CircularProgress, Menu, MenuItem} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Toolbar from '@mui/material/Toolbar';
import Typography from "@mui/material/Typography";
import LogoutButton from "../LogoutButton";
import {useAuth0} from "@auth0/auth0-react";
import { Link } from 'react-router-dom';

function Header() {
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const {user, isAuthenticated, isLoading} = useAuth0();

    if (isLoading) {
        return <CircularProgress/>;
    }

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <AppBar position="static" sx={{backgroundColor: "black", width: '100%', marginBottom: '1%'}}>
            <Box sx={{margin: 'auto', width: '80%'}}>
                <Toolbar disableGutters sx={{justifyContent: 'space-between'}}>
                    <Link to={`/`}>
                        <Box
                        component="img"
                        sx={{width: 150}}
                        alt="WeWork"
                        src={window.location.origin + "/logo.png"}
                    />
                    </Link>
                    <Box sx={{flexGrow: 0, align: 'left'}}>
                        <Tooltip title="Open settings">
                            <Box onClick={handleOpenUserMenu} sx={{p: 0}}>
                                Logged in as {user?.email}
                            </Box>
                        </Tooltip>
                        <Menu
                            sx={{mt: '45px'}}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            <MenuItem key='logout' onClick={handleCloseUserMenu}>
                                <Typography component={'div'} textAlign="center">
                                    <LogoutButton/>
                                </Typography>
                            </MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </Box>
        </AppBar>
    )
}

export default Header;

import {createContext, useContext} from 'react';
import {BuildingDevicesHostDataType} from "../types/buildingTypes";

interface DevicesHostsDataContextInterface{
    allHosts: BuildingDevicesHostDataType[] | undefined;
    hosts: BuildingDevicesHostDataType[] | undefined;
    loadingState: Boolean | undefined,
    setHosts: any,
    setLoadingState: any;
}

export const DevicesHostsDataContext = createContext<DevicesHostsDataContextInterface>({
  allHosts: undefined,
  hosts: undefined,
  loadingState: false,
  setHosts: {},
  setLoadingState: undefined,
});

export function useDevicesHostsDataContext(){
    const { allHosts, hosts, setHosts, loadingState, setLoadingState} = useContext(DevicesHostsDataContext);

    if (hosts === undefined || allHosts === undefined){
        throw new Error("useDevicesHostsDataContext must be used with a devicesHostsDataContext");
    }

    return {allHosts, hosts, setHosts, loadingState, setLoadingState};
}

import { useLocation } from 'react-router-dom';

const useRegionQueryParam = (): { selectedRegion: string | null } => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const region = queryParams.get('region');
  return { selectedRegion: region ? region.toLowerCase() : null };
};


export default useRegionQueryParam;
